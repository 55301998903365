import React from "react"

import Layout from "../components/layout-fr"
import SEO from "../components/seo"

const PricesPage = () => (
  <Layout>
    <SEO title="Tarifs" />
    <section class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-4">Tarifs</h1>
        </div>
      </div>
    </section>
    <div class="section" style={{ "padding-top": "1rem" }}>
      <div class="columns">
        <div class="column" />
        <div class="column is-5">
          <table class="table is-hoverable is-fullwidth">
            <thead>
              <tr>
                <th />
                <th>Tarif (€/nuit)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Adulte</td>
                <td>4,50€</td>
              </tr>
              <tr>
                <td>Enfant (de 5 à 12 ans)</td>
                <td>3,50€</td>
              </tr>
              <tr>
                <td>Enfant (- de 5)</td>
                <td>grátis</td>
              </tr>
              <tr>
                <td>Animaux</td>
                <td>1,00€</td>
              </tr>
              <tr>
                <td>Tente (moins de 6m / plus de 6m)</td>
                <td>5,00€ / 6,00€</td>
              </tr>
              <tr>
                <td>Caravane (moins de 5m / plus de 5m)</td>
                <td>5,00€ / 6,00€</td>
              </tr>
              <tr>
                <td>Camping-car (moins de 5m / plus de 5m)</td>
                <td>6,00€ / 7,00€</td>
              </tr>
              <tr>
                <td>Voiture</td>
                <td>3,50€</td>
              </tr>
              <tr>
                <td>Moto</td>
                <td>2,50€</td>
              </tr>
              <tr>
                <td>Vélo</td>
                <td>free</td>
              </tr>
              <tr>
                <td>Máchine à laver</td>
                <td>5,00€/lavage</td>
              </tr>
              <tr>
                <td>Électricité (disponible en certain emplacements)</td>
                <td>4,00€</td>
              </tr>
            </tbody>
          </table>
          <div class="content">
            <p>
              L’utilisation de tous les équipements (barbecues, coin repas,
              réfrigérateur, congélateur, jeux, etc.) est gratuite.{" "}
            </p>
          </div>
        </div>
        <div class="column" />
      </div>
    </div>
  </Layout>
)

export default PricesPage
