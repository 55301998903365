import PropTypes from "prop-types"
import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faImages,
  faTree,
  faCompass,
  faEnvelope,
  faEuroSign
} from "@fortawesome/free-solid-svg-icons"
import Flag from "react-world-flags"

const NavBar = ({ toggleNavbar, isActive }) => (
    <nav
      class="navbar"
      role="navigation"
      aria-label="main navigation"
      style={{ "border-bottom": "3px solid primary"}}
    >
      <div class="navbar-brand">
        <a class="navbar-item" href="/">
          <img src={"/img/camping-logo-1.png"} alt="img" />
        </a>

        <div
          role="button"
          class={"navbar-burger " + (isActive ? "is-active" : "")}
          data-target="navMenu"
          aria-label="menu"
          aria-expanded="false"
          onClick={toggleNavbar}
        >
          <span />
          <span />
          <span />
        </div>
      </div>

      <div id="navMenu" class={"navbar-menu " + (isActive ? "is-active" : "")}>
        <div class="navbar-start">
          <a class="navbar-item" href="/index-fr/">
            <FontAwesomeIcon icon={faTree} />
            &nbsp;Présentation
          </a>

          <a class="navbar-item" href="/location-fr/">
            <FontAwesomeIcon icon={faCompass} />
            &nbsp;Localisation
          </a>

          <a class="navbar-item" href="/gallery-fr/">
            <FontAwesomeIcon icon={faImages} />
            &nbsp;Photos
          </a>

          <a class="navbar-item" href="/prices-fr/">
            <FontAwesomeIcon icon={faEuroSign} />
            &nbsp;Tarifs
          </a>

        </div>

        <div class="navbar-end">
          <div class="navbar-item">
            <div class="buttons">
              <a class="button is-primary" href="/contacts-fr/">
                <FontAwesomeIcon icon={faEnvelope} />
                &nbsp;Contact
              </a>
              <a class="button is-light" href="/">
                <div>
                  <span>
                    <Flag code="gbr" style={{ height: ".8rem" }} />
                  </span>
                  <span> English</span>
                </div>
              </a>
              <a class="button is-light" href="/index-pt/">
                <div>
                  <span>
                    <Flag code="prt" style={{ height: ".8rem" }} />
                  </span>
                  <span> Português</span>
                </div>
              </a>
              <a class="button is-light" href="/index-fr/">
                <div>
                  <span>
                    <Flag code="fra" style={{ height: ".8rem" }} />
                  </span>
                  <span> Français</span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
)

NavBar.propTypes = {
  siteTitle: PropTypes.string,
}

NavBar.defaultProps = {
  siteTitle: ``,
}

export default NavBar
